import $ from "jquery";
import "slick-carousel";
import "./polyfills";

$(".js-dc-slider").slick({
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  dots: true,
  arrows: false,
  speed: 500,
  fade: true,
  draggable: false,
  appendDots: $(".js-dc-slider-dots")
});

$(".js-dc-slider-navigation").slick({
  slidesToShow: 2,
  slidesToScroll: 2,
  asNavFor: ".js-dc-slider",
  dots: false,
  focusOnSelect: true
});

$(".js-testimonial-slider").slick({
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  dots: true,
  arrows: true,
  speed: 500,
  prevArrow: $(".js-testimonial-slider-prev-arrow"),
  nextArrow: $(".js-testimonial-slider-next-arrow"),
  appendDots: $(".js-testimonial-slider-dots"),
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        arrows: false
      }
    }
  ]
});

const prefillValues = {
  light: {
    phase_study_type: 1,
    number_of_sites: 1,
    sponsor_type: 1,
    number_of_invest: 500
  },
  pro: {
    phase_study_type: 2,
    number_of_sites: 10,
    sponsor_type: 2,
    number_of_invest: 1000
  },
  premium: {
    phase_study_type: 3,
    number_of_sites: 100,
    sponsor_type: 3,
    number_of_invest: 10000
  },
  custom: {
    phase_study_type: "none",
    number_of_sites: 0,
    sponsor_type: "none",
    number_of_invest: 0
  }
};

const initNavigation = () => {
  const navigationBurgerEl = document.querySelector(".js-main-nav-burger");
  const bodyEl = document.body;
  navigationBurgerEl.addEventListener("click", () => {
    if (bodyEl.classList.contains("is-main-nav-opened")) {
      document.body.classList.remove("is-main-nav-opened");
    } else {
      document.body.classList.add("is-main-nav-opened");
    }
  });
};

const preFillInputs = type => {
  const inputEls = document.querySelectorAll(".js-prefill-input");
  inputEls.forEach(input => {
    input.value = prefillValues[type][input.name];
  });
};

const radiosInit = () => {
  const radioEls = document.querySelectorAll(
      '.js-choices-tab-wrapper input[name="radio-tab"][type="radio"]'
  );

  radioEls.forEach(radioItem => {
    radioItem.addEventListener("click", function(event) {
      preFillInputs(event.target.dataset.prefill_type);
    });
  });
};

document.addEventListener("DOMContentLoaded", function() {
  radiosInit();
  preFillInputs("light");
  initNavigation();
});

const scrollButton = document.querySelector(".js-scroll-to-top");

function scrollToTop(event) {
  event.preventDefault();
  window.scrollTo(0, 0);
}

scrollButton.addEventListener("click", scrollToTop);


var acc = document.getElementsByClassName("faq__header");
var acc2 = document.getElementsByClassName("garant__name");
var i, j;

for (i = 0; i < acc.length; i++) {
  acc[i].onclick = function(){
    this.classList.toggle("active");
    this.nextElementSibling.classList.toggle("show");
  };
}

for (j = 0; j < acc2.length; j++) {
  acc2[j].onclick = function () {
    this.classList.toggle("active");
    this.nextElementSibling.classList.toggle("show");
  };
}






/*
/!*neco je spatne*!/
$(document).ready(function () {
  $(document).on("scroll", onScroll);

  //tohle myslim jede, to je klikani
  $('a[href^="#"]').on('click', function (e) {
     e.preventDefault();
     $(document).off("scroll");
    $('a').each(function () {
      $(this).removeClass('active');
    })
    $(this).addClass('active');


    var target = this.hash,
        menu = target;
    target = $(target);
    $('html, body').stop().animate({
      'scrollTop': $target.offset().top+2
    }, 500, 'swing', function () {
      window.location.hash = target;
      $(document).on("scroll", onScroll);
    });
  });
});*/


/*
const navbar = document.querySelector('.navigation__link');
window.onscroll = () => {
  if (window.scrollY > 100) {
    navbar.classList.add('-ctive');
  } else {
    navbar.classList.remove('active');
  }
};

*/


/*var nav = document.querySelector('.navigation__link'); // Identify target

window.addEventListener('scroll', function(event) { // To listen for event
  event.preventDefault();

  if (window.scrollY >= 10) { // Just an example
    nav.style.backgroundColor = '#000'; // or default color
  } else {
    nav.style.backgroundColor = 'transparent';
  }
});*/


// ne ze by to uplne fungoalo
function onScroll(event){
  var scrollPos = $(document).scrollTop();
  $('.navigation__list-wrapper a').each(function () {
    var currLink = $(this);
    var refElement = $(currLink.attr("href"));
    if (refElement.position().top <= scrollPos && refElement.position().top + refElement.height() > scrollPos) {
      $('.navigation__list-wrapper ul li a').removeClass("active");
      currLink.addClass("active");
    }
    else{
      currLink.removeClass("active");
    }
  });
}

window.$ = $